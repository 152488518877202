import React from "react";
import how_work_1 from "../../../assets/images/outer/how_work_1.svg";
import how_work_2 from "../../../assets/images/outer/how_work_2.svg";
import how_work_3 from "../../../assets/images/outer/how_work_3.svg";
import how_work_4 from "../../../assets/images/outer/how_work_4.svg";
import { _t } from "../../Common/components/InjectIntlContext";
import { Typography } from "antd";
import HowItWorkCard from "./HowItWorkCard";

const slides = (_t: any) => [
  {
    title: _t("how_1"),
    icon: how_work_1,
  },
  {
    title: _t("how_2"),
    icon: how_work_2,
  },
  {
    title: _t("how_3"),
    icon: how_work_3,
  },
  {
    title: _t("how_4"),
    icon: how_work_4,
  },
];

const HowItWorks = () => {
  const howItWorksCards = slides(_t);
  const howItWorkItem = (card, idx) => (
    <li className="how-it-work-list__item" key={card.title + idx}>
      <HowItWorkCard info={card} />
    </li>
  );

  return (
    <section className="how-it-works custom-section">
      <div className="container">
        <Typography.Title level={2} className="custom-section__title">
          {_t("how_it_works")}
        </Typography.Title>
        <ul className="how-it-work-list">
          {howItWorksCards.map(howItWorkItem)}
        </ul>
        <div className="get-started">
          <a href={_t("dashboard_link_sign_up")} target="_blank" rel="noreferrer noopener" className="btn">
            {_t("get_started")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
